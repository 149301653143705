<script>
import { InformationCircleIcon, ExclamationCircleIcon } from '@vue-hero-icons/solid';

export default {
  props: {
    type: {
      type: String,
      default: 'info',
      validator: (value) => ['info', 'warning'].indexOf(value) !== -1,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      baseClasses: ['border-t-4', 'rounded-b', 'px-4', 'py-3', 'shadow-md'],
      typeClassMap: {
        info: ['bg-teal-100', 'border-teal-500', 'text-teal-900'],
        warning: ['bg-red-100', 'border-red-500', 'text-red-900'],
      },
    };
  },

  components: {
    InformationCircleIcon,
    ExclamationCircleIcon,
  },
};
</script>

<template>
  <div :class="[...typeClassMap[type], ...baseClasses]" role="alert">
    <div class="flex">
      <div class="flex items-center mr-4">
        <InformationCircleIcon v-if="type === 'info'" />
        <ExclamationCircleIcon v-else-if="type === 'warning'" />
      </div>
      <div class="text-left">
        <p class="font-bold">{{ title }}</p>
        <p class="text-sm">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<style></style>
